import React, { Component } from "react";
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
  MDBHamburgerToggler } from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import { Link } from "react-router-dom";


class Mobile_menu extends Component {
  state = {
    collapse1: false,
    collapseID: ''
  }
  
  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
  }
  
  toggleSingleCollapse = collapseId => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId]
    });
  }
  

  render() {

    return (

      <Router>

        <MDBNavbar>
          <MDBHamburgerToggler id="hamburger1" onClick={()=> this.toggleSingleCollapse('collapse1')} />
            <MDBCollapse isOpen={this.state.collapse1} navbar>
              <MDBNavbarNav left>
                <MDBNavItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/'}>Home</MDBDropdownItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/about_us'}>About Us</MDBDropdownItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/services'}>Our Services</MDBDropdownItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/our_process'}>Our Process</MDBDropdownItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/countries_we_serve'}>Countries we serve</MDBDropdownItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/current_openings'}>Current Openings</MDBDropdownItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/faq'}>Faqs</MDBDropdownItem>
                <MDBDropdownItem href={process.env.PUBLIC_URL + '/contact_us'}>Contact Us</MDBDropdownItem>
                </MDBNavItem> 
              </MDBNavbarNav>
            </MDBCollapse>
        </MDBNavbar>
      </Router>
    );
  }
}

export default Mobile_menu;