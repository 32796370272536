import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export class Carrer_01 extends Component {
    render() {
        
        return (
            <div className="site-main">
                <Header />

                {/* PageHeader */} 
                <PageHeader           
                    title="Our Services" 
                    breadcrumb="Our Services" 
                />
                {/* PageHeader end */}


                {/* process-section */}
                <section className="ttm-row process-section bg-theme-GreyColor clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title title-style-center_text">
                                    <div className="title-header">
                                        <h2 className="title">Our Services</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="featuredbox-number bg-theme-WhiteColor box-shadow p-30 mt-15">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box icon-align-top-content style6">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round">
                                                      <img src="images/engineer.png"></img>
                                                        <span className="fea_num">
                                                            <i className="ttm-num ti-info"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>Civil</h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Daksh Human Resources provides diverse manpower solutions for the civil sector, including recruitment and placement of skilled laborers, engineers, project managers, site supervisors, surveyors, architects, and structural engineers.</p>
                                                    </div>
                                                </div>
                                            </div>{/* featured-icon-box end */}
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box icon-align-top-content style6">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round">
                                                    <img src="images/technician.png"></img>
                                                        <span className="fea_num">
                                                            <i className="ttm-num ti-info"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>Mechanical</h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Daksh Manpower provides a range of jobs in the mechanical industry, including but not limited to positions such as mechanical engineers, design engineers, maintenance technicians, quality control inspectors, production managers and project managers.</p>
                                                    </div>
                                                </div>
                                            </div>{/* featured-icon-box end */}
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box icon-align-top-content style6" id="our_verticals">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round">
                                                    <img src="images/oil.png"></img>
                                                        <span className="fea_num">
                                                            <i className="ttm-num ti-info"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>Oil & Gas</h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Our partner offers top-notch manpower solutions for the oil and gas sector, offering diverse job placements for technicians, engineers, project managers, and safety specialists. </p>
                                                    </div>
                                                </div>
                                            </div>{/* featured-icon-box end */}
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box icon-align-top-content style6">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round">
                                                    <img src="images/facility-management.png"></img>
                                                        <span className="fea_num">
                                                            <i className="ttm-num ti-info"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>Facility Management</h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Our facility and management services offer tailored personnel solutions for various positions, including technicians, maintenance, and administrative professionals.</p>
                                                    </div>
                                                </div>
                                            </div>{/* featured-icon-box end */}
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box icon-align-top-content style6">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round">
                                                    <img src="images/healthcare.png"></img>
                                                        <span className="fea_num">
                                                            <i className="ttm-num ti-info"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>Healthcare</h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Our specialized manpower solutions address the healthcare sector's human resource shortfall by providing employment placements for various healthcare fields. </p>
                                                    </div>
                                                </div>
                                            </div>{/* featured-icon-box end */}
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box icon-align-top-content style6" id="our_verticals"> 
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round">
                                                    <img src="images/hospitality.png"></img>
                                                        <span className="fea_num">
                                                            <i className="ttm-num ti-info"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>Hospitality</h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Our company specializes in providing tailored manpower solutions for the hospitality sector, ensuring a skilled and dedicated workforce for various roles. </p>
                                                    </div>
                                                </div>
                                            </div>{/* featured-icon-box end */}
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box icon-align-top-content style6">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round">
                                                    <img src="images/information-technology.png"></img>
                                                        <span className="fea_num">
                                                            <i className="ttm-num ti-info"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>Information Technology</h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Experts in providing customized personnel solutions for IT industry, offering various positions including project management, systems analysis, network engineering, software development, and cyber security.
</p>
                                                    </div>
                                                </div>
                                            </div>{/* featured-icon-box end */}
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box icon-align-top-content style6">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round">
                                                    <img src="images/shopping-store.png"></img>
                                                        <span className="fea_num">
                                                            <i className="ttm-num ti-info"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>Retail</h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Daksh Human Resources is a leading provider of tailored manpower solutions and services in the retail sector, offering diverse job opportunities for various roles.</p>
                                                    </div>
                                                </div>
                                            </div>{/* featured-icon-box end */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* row end */}
                    </div>
                </section>
                {/* process-section end */}
                     
            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Incredible Recruitment & Staffing Agency</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>"We are a seasoned manpower solutions provider with a decade of experience, specialising in offering comprehensive HR services for the Gulf and European regions."</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                href={process.env.PUBLIC_URL + '/current_openings'}>Apply Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}

                <Footer/>

            </div>
        )
    }
}


export default Carrer_01;