import React, { Component } from 'react';
import Slider from 'react-slick';
import Header from '../components/layout/Header';
import { Banner } from '../components/banner/Home1_banner';
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import ProgressBar from 'react-animated-progress-bar';


export class Home extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    render() {
      var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [{
    
          breakpoint: 768,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 575,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }]
      };
        
        return (       
          <div className="site-main">

            <Header/>
            
            {/* Banner */} 
            <Banner/>
            {/* Banner end */} 
             {/* features-section */}
 <section className="ttm-row features-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section title */}
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                    <h3>Get <span className="text-theme-SkinColor">Appropriate</span></h3>
                                    <h2 className="title">Search Job By Industry</h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                    </div>{/* row end */}
                    {/* row */}
                    <div className="row row-equal-height mb_10">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* finance-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: '' }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* finance-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/engineer.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Civil</h3>
                                    </div>
                                    <div className="featured-desc">
<p>Daksh Human Resources provides diverse manpower solutions for the civil sector, including recruitment and placement of skilled laborers, engineers, project managers, site supervisors, surveyors, architects, and structural engineers. Their focus on connecting individuals with opportunities fosters growth and success in the industry.</p>
                                    </div>
                                   <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/civil'}>Apply Jobs!</a> 
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1 active">
                                <div className="ttm-box-view-overlay">
                                    {/* agriculture-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: '' }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* agriculture-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/technician.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Mechanical</h3>
                                    </div>
                                    <div className="featured-desc">
<p>
Daksh Manpower provides a range of jobs in the mechanical industry, including but not limited to positions such as mechanical engineers, design engineers, maintenance technicians, quality control inspectors, production managers, project managers, CNC (computer numeric controlled) operators, and more. Their services cater to various roles within the mechanical sector, ensuring skilled manpower solutions to meet industry demands.    </p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/mechanical'}>Apply Jobs!</a>
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* technology-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage:''  }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* technology-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/oil.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Oil & Gas</h3>
                                    </div>
                                    <div className="featured-desc">
<p>Our partner offers top-notch manpower solutions for the oil and gas sector, offering diverse job placements for technicians, engineers, project managers, and safety specialists. Our curated talent pool is ready for short-term or permanent assignments, contributing to growth and success in the industry.</p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/oil_gas'}>Apply Jobs!</a> 
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* construction-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: '' }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* construction-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/healthcare.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Healthcare</h3>
                                    </div>
                                    <div className="featured-desc">
<p>Our specialized manpower solutions address the healthcare sector's human resource shortfall by providing employment placements for various healthcare fields. We find, screen, and deliver the best talent pool, ensuring high-quality patient care and professional development. Our goal is to transform healthcare organizations and promote the best patient experience.</p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/healthcare'}>Apply Jobs!</a> 
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* manufacturing-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: '' }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* manufacturing-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/hospitality.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Hospitality</h3>
                                    </div>
                                    <div className="featured-desc">
<p>Our company specializes in providing tailored manpower solutions for the hospitality sector, ensuring a skilled and dedicated workforce for various roles. We focus on sourcing, recruiting, and placing personnel who embody excellence and customer service.</p>
                                    </div>
                                     <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/hospitality'}>Apply Jobs!</a> 
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>                        
                        <div class="col-lg-6 col-md-6 col-sm-6">
    <div class="featured-icon-box icon-align-top-content style1 active">
        <div class="ttm-box-view-overlay">
            <div class="ttm-col-bgimage-yes ttm-bg h-100">
                <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div class="layer-content"></div>
            </div>
        </div>
        <div class="featured-icon">
            <div class="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg"><img class="img-fluid" src="images/facility-management.png" /></div>
        </div>
        <div class="featured-content">
            <div class="featured-title"><h3>Facility Management</h3></div>
            <div class="featured-desc">
                <p>
                    Our facility and management services offer tailored personnel solutions for various positions, including technicians, maintenance, and administrative professionals. We specialize in finding, hiring, and placing suitable
                    individuals for various industries, ensuring efficient operations, managing maintenance, and supervising logistics. Our commitment to quality and understanding business demands ensures successful facilities.
                </p>
            </div>
            <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/facility_management'}>Apply Jobs!</a> 
        </div>
    </div>
</div>
<div class="col-lg-6 col-md-6 col-sm-6">
    <div class="featured-icon-box icon-align-top-content style1">
        <div class="ttm-box-view-overlay">
            <div class="ttm-col-bgimage-yes ttm-bg h-100">
                <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div class="layer-content"></div>
            </div>
        </div>
        <div class="featured-icon">
            <div class="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg"><img class="img-fluid" src="images/information-technology.png" /></div>
        </div>
        <div class="featured-content">
            <div class="featured-title"><h3>Information Technology</h3></div>
            <div class="featured-desc">
                <p>
                    Experts in providing customized personnel solutions for IT industry, offering various positions including project management, systems analysis, network engineering, software development, and cyber security. Comprehensive
                    candidate selection ensures technical capabilities and adaptability, fostering IT excellence and success.
                </p>
            </div> <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/information_technology'}>Apply Jobs!</a> 
        </div>
    </div>
</div>

                        <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* transport-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: '' }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* transport-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/shopping-store.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Retail</h3>
                                    </div>
                                    <div className="featured-desc">
<p>Daksh Human Resources is a leading provider of tailored manpower solutions and services in the retail sector, offering diverse job opportunities for various roles. Their comprehensive database ensures candidates possess the necessary skills and passion for success. They connect businesses with the right talent, meeting customer expectations and driving growth in a competitive market.</p>
                                    </div>
                                   <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/retail'}>Apply Jobs!</a> 
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                       
                    </div>{/* row end */}
                </div>
            </section>
            {/* features-section end */}          
            {/* about-section */}
            <section className="ttm-row about-section bg-layer-equal-height clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row g-0">
                        <div className="col-md-6">
                            <div className="pb-70 pb-md-50 pr-60 pr-md-0">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h2 className="title">About Us</h2>
                                    </div>

                                </div>{/* section title end */}
                                <div className="mb-md-40 mb-30">
                                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor text-theme-DarkColor">
                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Founded in 2013, Daksh Human Resources has emerged as a pioneering force in the realm of overseas recruitment and manpower solutions in India. With a visionary approach and a commitment to excellence, Daksh Human Resources has steadily grown to become a trusted partner for businesses across the Middle East, Europe, and Eastern Europe. Our journey began with the recognition of a pressing need in the global market for a reliable and efficient recruitment agency that could bridge the gap between talent demand and supply.</div></li>
                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Daksh Human Resources is not just another recruitment agency; we are an entity duly registered and approved by the Ministry of External Affairs (MEA) of India. Our official registration under the MEA bears testament to our credibility and legitimacy. Our Registration number, B-0497/DEL/COM/1000+/5/8948/2013, stands as a symbol of our compliance and dedication to ethical recruitment practices.</div></li>
                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Daksh HR specialises in providing a comprehensive array of Indian workers, ranging from skilled and semi-skilled to unskilled labour. Our client base spans across various industries, including construction, oil and gas, transportation, and more. We take pride in meticulously selecting and preparing Indian talent to meet the unique requirements of each project and industry.</div></li>
                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Our reach extends beyond borders. With a focus on the Middle East, Europe, and Eastern Europe, we have cultivated an extensive network that allows us to identify, assess, and present the most suitable candidates for our clients' needs. Our expertise in navigating international regulations and requirements ensures a seamless recruitment process for both clients and candidates.</div></li>
                                    </ul>
                                </div>
                                <div className="title-desc"><p>If your company needs Indian workers, then look no further. Send in a request and we would get back to you within 24 Hours.</p></div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {/* col-img-img-one */}
                            <div className="col-bg-img-one ttm-col-bgimage-yes ttm-bg"  >
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: 'url(images/cp.jpg)' }}></div>
                                <div className="layer-content"></div>
                                <img src="images/cp.jpg" className="img-fluid col-bg-img-res" alt="bgimage" />
                            </div>{/* col-img-bg-img-one end */}
                        </div>
                    </div>
                                    {/* padding_zero-section */}
                <section className="ttm-row padding_zero-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="pt-5 bg-theme-SkinColor mt_100 mt-md-60"></div>
                            <div className="bg-theme-WhiteColor box-shadow pt-md-40 pb-md-30">
                                <div className="row g-0 ttm-vertical_sep">
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        {/* fid */}
                                        <div className="ttm-fid inside ttm-fid-view-topicon style2">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-headhunting"></i>
                                            </div>
                                            <div className="ttm-fid-contents">
                                                <h4><CountUp start={0} end={13} duration={20} delay={2} /></h4>
                                                <h3 className="ttm-fid-title">Years of Experience</h3>
                                            </div>
                                        </div>{/* fid end */}
                                    </div>
                                   
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        {/* fid */}
                                        <div className="ttm-fid inside ttm-fid-view-topicon style2">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-recruitment-4"></i>
                                            </div>
                                            <div className="ttm-fid-contents">
                                                <h4><CountUp start={0} end={55000} duration={20} delay={2} /></h4>
                                                <h3 className="ttm-fid-title">Mobilization</h3>
                                            </div>
                                        </div>{/* fid end */}
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-6">
                                        {/* fid */}
                                        <div className="ttm-fid inside ttm-fid-view-topicon style2">
                                            <div className="ttm-fid-icon-wrapper">
                                                <i className="flaticon flaticon-recruitment-3"></i>
                                            </div>
                                            <div className="ttm-fid-contents">
                                                <h4><CountUp start={0} end={150} duration={20} delay={2} /></h4>
                                                <h3 className="ttm-fid-title">Clients</h3>
                                            </div>
                                        </div>{/* fid end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* padding_zero-section end */}  
                    <div className="row g-0" id="col-bg-img-two">
                        <div className="col-md-6">
                            {/* col-img-img-two */}
                            <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg mt-md-50">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{ backgroundImage: 'url(images/two.jpg)' }}></div>
                                <div className="layer-content"></div>
                                <img src="images/two.jpg" className="img-fluid col-bg-img-res" alt="bgimage" />
                            </div>{/* col-img-bg-img-two end */}
                        </div>
                        <div className="col-md-6">
                            <div className="pt-70 pt-md-50 pl-70 pl-md-0">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h2 className="title">Manpower Requirement Experts
In Middle East and Europe</h2>
                                    </div>
                                    <div className="title-desc"><p>With a decade of unwavering dedication and expertise, Daksh Human Resources stands as a beacon of excellence in providing top-tier manpower solutions and services tailored to the unique demands of the Gulf and European regions.
                                     Our journey, spanning more than ten years, has been characterised by a commitment to integrity, innovation, and a deep understanding of the industries we serve.
                                        As a trusted recruitment agency, we have successfully connected countless talented individuals with thriving opportunities, contributing to the growth of businesses and the enrichment of careers. Explore our website to discover how our decade-long experience can be the cornerstone of your success.</p>
                                        </div>
                                </div>{/* section title end */}
                            </div>
                        </div>
                    </div>
                    {/* row end */}
                </div>
            </section>
            {/* about-section */}            
            {/* aboutus-section */}
            <section className="ttm-row about-section bg-img2 bg-theme-DarkColor ttm-bg ttm-bgimage-yes text-theme-WhiteColor clearfix" style={{ backgroundImage: 'url("images/row-bgimage-2.jpg")' }}>
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer bg-theme-DarkColor"></div>
                <div className="container">
                    {/* row */}
                    <div className="row align-items-center">
                        <div className="col-xl-5 col-lg-4 col-md-12">
                            {/* section title */}
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>More than 150 <span className="text-theme-SkinColor">happy clients</span></h3>
                                    <h2 className="title">The Most Trusted and Professional Recruitment <span className="text-theme-SkinColor"> Agency</span></h2>
                                </div>
                            </div>{/* section title end */}
                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white mt-15" 
                            href={process.env.PUBLIC_URL + '/current_openings'}>Hiring Now!</a>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-5">
                            <div className="pt-lg-30">
                                {/* fid */}
                                <div className="ttm-fid inside ttm-fid-view-topicon style1">
                                    <div className="ttm-fid-icon-wrapper">
                                        <i className="flaticon flaticon-recruiting"></i>
                                    </div>
                                    <div className="ttm-fid-contents">
                                        <h4><CountUp start={0} end={55000} duration={20} delay={2} /></h4>
                                        <h3 className="ttm-fid-title">Mobilization</h3>
                                    </div>
                                </div>{/* fid end */}
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-8 col-sm-7">
                            <div className="pt-lg-20 pb-30">
                                <p className="text-theme-WhiteColor">Daksh Human Resources is a company dedicated to offering comprehensive HR solutions with a decade of industry presence.</p>
                            </div>
                            {/* ttm-progress-bar */}
                            <div className="ttm-progress-bar style2 clearfix">
                                <h3 className="progressbar-title">Visa Assurance</h3>
                                <ProgressBar rect percentage="99" />
                            </div>
                            {/* ttm-progress-bar end */}
                            {/* ttm-progress-bar */}
                            <div className="ttm-progress-bar style2 clearfix">
                                <h3 className="progressbar-title">Job Satisfaction</h3>
                                <ProgressBar rect percentage="100" />
                            </div>
                            {/* ttm-progress-bar end */}
                        </div>
                        <div className="col-lg-12">
                            <div className="pt-100 pt-md-0"></div>
                        </div>
                    </div>
                </div>
            </section> 
           {/* aboutus-section end */}        
            {/* testimonial-section */}
            <section className="ttm-row testimonial-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                    <h2 className="title">Our Clients</h2>
                                </div>
                            </div>
                        </div>
                      </div>
                    <Slider className="row slick_slider slick-arrows-style1" {...slick_slider} slidesToShow={5} arrows={true}>
                        <div className="col-lg-12"> 
                            {/* testimonials */}                                      
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/01.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                                </div>
                     {/* testimonials end */}
              
                        <div className="col-lg-12"> 
                            {/* testimonials */}
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/02.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                            </div> {/* testimonials end */}
                        </div>
                        <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/03.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div> {/* testimonials end */}
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/04.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/05.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/06.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/07.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/08.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/09.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/10.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/11.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/12.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/13.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/14.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/15.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/16.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/17.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/18.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/19.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/20.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/21.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/22.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12"> 
                            {/* testimonials */}                                       
                                <div className="testimonial-bottom">
                                    <div className="testimonial-avatar">
                                        <div className="testimonial-img">
                                            <img className="img-fluid" src="images/23.png" alt="testimonial-img" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </Slider>
                </div>
            </section>
            {/* testimonial-section end */}

             {/* action-section */}
             <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Incredible Recruitment & Staffing Agency</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>"We are a seasoned manpower solutions provider with a decade of experience, specialising in offering comprehensive HR services for the Gulf and European regions."</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                href={process.env.PUBLIC_URL + '/current_openings'}>Apply Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}
 
            <Footer/>
                        
          </div>
        )
    }
}


export default Home;
