import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';


export class Candidate_list extends Component {

    constructor() {
        super();
        this.state = {
          name: "React"
        };
        this.onChangeValue = this.onChangeValue.bind(this);

    }
    
    onChangeValue(event) {
        console.log(event.target.value);
    }

    render() {
        return (

            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="Countries We Serve"
                    breadcrumb="Countries We Serve"
                />
                {/* PageHeader end */}
  <section class="ttm-row grid-section clearfix">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="featured-imagebox box-shadow" id="container">
                    <div class="featured-thumbnail">
                        <img class="img-fluid_cont" src="images/usa.jpg" alt="" />
                    </div>
                     <div class="featured-content p-30">
                        <div class="featured-title">
                            <h3><a href="">U.A.E</a></h3>
                            </div>
                             </div>
                    <div class="overlay">
                    <div class="text p-30">
                        <div class="featured-desc">
                            <h5>UAE Employment – Visa Process & Requirements</h5>
                            <p>Certificate issued or a stamp marked (on the applicant’s passport) by the immigration authorities of the country to indicate that the applicant’s credentials have been verified and he or she has been granted resident status permission to enter the country for a temporary stay within a specified period (3 years).</p>
                            <div class="featured-title">
                            <h3><a href="">From the Applicant</a></h3>
                            </div>
                            <ul class="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Passport copy (valid for six months)</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Photograph x 8 (White back ground)</div>
                                </li>
                                <li>
                                    <div class="ttm-list-li-content">Authorized Degree Certificate (If Required)</div>
                                </li>
                            </ul><br></br>
                            <div class="featured-title">
                            <h3><a href="">From the Company</a></h3>
                            </div>
                            <ul class="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Trade License Copy</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Labor Card Copy</div>
                                </li>
                                <li>
                                    <div class="ttm-list-li-content">Immigration Card Copy</div>
                                </li>
                                <li>
                                    <div class="ttm-list-li-content">Tenancy Contract Copy</div>
                                </li>
                                <li>
                                    <div class="ttm-list-li-content">Location Map of The Company
</div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="featured-imagebox box-shadow" id="container">
                    <div class="featured-thumbnail">
                        <img class="img-fluid_cont" src="images/Oman.jpg" alt="" />
                    </div>
                     <div class="featured-content p-30">
                        <div class="featured-title">
                            <h3><a href="">Oman</a></h3>
                            </div>
                             </div>
                    <div class="overlay" id="hover_right">
                    <div class="text p-30">
                        <div class="featured-desc">
                            <h5>Oman Employment – Visa Process & Requirements</h5>
                            <div class="featured-title">
                            <h3><a href="">Regular Visas</a></h3>
                            </div>
                            <ul class="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Original, signed Indian passport with a least 6 month of remaining validity Passport-type photographs :2</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Visa application form filled out in details (block letters) and signed by the applicant Copy of round-trip tickets or itinerary.</div>
                                </li>
                                <li>
                                    <div class="ttm-list-li-content">Approval issued by the passport and immigration department in Oman A letter of invitation is required if staying with family or friends.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">A personal letter from the applicant addressed to the Embassy of Oman, explaining the purposed of the trip, dates of travel, cities to be visited, and place of accommodation.</div>
                                </li>
                            </ul><br></br>
                            <div class="featured-title">
                            <h3><a href="">Working Visas</a></h3>
                            <p>For more information related to visa in Oman, contact DAKSH NETWORKING at <a href="mailto:hr@dakshnetworking.com">hr@dakshnetworking.com</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="featured-imagebox box-shadow" id="container">
                    <div class="featured-thumbnail">
                        <img class="img-fluid_cont" src="images/Saudi_Arabia.jpg" alt="" />
                    </div>
                     <div class="featured-content p-30">
                        <div class="featured-title">
                            <h3><a href="">Saudi Arabia</a></h3>
                            </div>
                             </div>
                    <div class="overlay">
                    <div class="text p-30">
                        <div class="featured-desc">
                            <h5>Saudi Arabia Employment – Visa Process & Requirements</h5>
                            </div>
                            <ul class="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                <li class="pb-5">
                                    <div class="ttm-list-li-content"><b>Electronic Visa Authorization Slip - </b>(E-Wakala) in the name of company applied for Visa.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content"><b>Medical Report from GCC Panel of doctors – </b> The addresses of various GCC approved Medical Centers in India can be obtained at the website <a href="www.gamcaindia.org/index.htm" target="_blank">www.gamcaindia.org/index.htm</a></div>
                                </li>
                                <li>
                                    <div class="ttm-list-li-content"><b>Passport – </b> Should have minimum 6 months validity & should contain at least 3-4 continuous empty pages i.e. 2 continuous empty sheets.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content"><b>Photos – </b>15 Nos. Colour, White Background, Size 4×6 cm.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content"><b>Education Certificate Attestation – </b>Professional Application such as Engineer, Technician, Accounts, Systems Analyst, Finance Analyst, Banking Expert, all Senior/professional positions must be holding relevant degree certificates which have to be attested / authenticated from HRD, MEA (Govt.of India) Saudi Culture Attached and Saudi Embassy, New Delhi.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content"><b>Experience Certificate – </b>If in case there is demand for experienced employees then experience certificate is must.</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>
            <div class="col-lg-6 col-md-6 col-sm-12 m-auto">
            <div class="featured-imagebox box-shadow" id="container">
                    <div class="featured-thumbnail">
                        <img class="img-fluid_cont" src="images/Kuwait.jpg" alt="" />
                    </div>
                     <div class="featured-content p-30">
                        <div class="featured-title">
                            <h3><a href="">Kuwait</a></h3>
                            </div>
                             </div>
                    <div class="overlay" id="hover_right">
                    <div class="text p-30">
                        <div class="featured-desc">
                            <h5>Kuwait Employement Visa</h5>
                            <p>The work visa/permit in Kuwait is issued under Article 17 and 18 of the country’s immigration laws. Article 17 is for Public Sector Employees and Article 18 is for Private Sector Employees.</p>
                            <div class="featured-title">
                            <h3><a href="">Process</a></h3>
                            <p>If you are an Indian who has secured a job in Kuwait, then your employer has to first get for a work permit from the Ministry of Social Affairs and Labour. Your employer has to submit your personal details to avail this permit. You will have to send a copy of your passport to your employer, which they have to show to the Ministry of Social Affairs and Labour.</p>
                            <p>Once your employer receives the work visa/permit, they have to send the same to you (the employee) along with the NOC. After you receive the 2 documents, you have to first go for a medical test in India. The test has to be done at a clinic recognized by the Kuwaiti Consulate. The medical Centre will require you to display the original visa. It will conduct a blood test and a x-ray. This medical report will prove that you have a good health and you do not have any epidemic diseases. Apart from this you also have to get a Police Clearance Certificate (PCC) from the RPO (Regional Passport Office).</p>
                            </div>

                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12" id="container-id">
            <div class="featured-imagebox box-shadow" id="container" >
                    <div class="featured-thumbnail">
                        <img class="img-fluid_cont" src="images/canada.jpg" alt=""/>
                    </div>
                     <div class="featured-content p-30">
                        <div class="featured-title">
                            <h3><a href="">Bulgaria</a></h3>
                            </div>
                             </div>
                    <div class="overlay">
                    <div class="text p-30">
                         <div class="featured-desc">
                            <h5>Bulgaria Employment – Visa Process & Requirements</h5>
                            <div class="featured-title">
                            <h3><a href="">For Applicants</a></h3>
                            </div>
                            <ul class="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Obtain a job offer from a registered Bulgarian company.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Gather the necessary documents: a valid passport, a job contract, health insurance, and proof of accommodation.</div>
                                </li>
                                <li>
                                    <div class="ttm-list-li-content">Apply at the Bulgarian embassy or consulate in their home country.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Attend an interview, if required.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Await visa approval.</div>
                                </li><li class="pb-5">
                                    <div class="ttm-list-li-content">Enter Bulgaria and apply for a residence permit within 3 days.</div>
                                </li><li class="pb-5">
                                    <div class="ttm-list-li-content">A medical examination may be required.</div>
                                </li><li class="pb-5">
                                    <div class="ttm-list-li-content">Ensure compliance with visa regulations during the stay.</div>
                                </li>
                            </ul><br></br>
                            <div class="featured-title">
                            <h3><a href="">For Companies:</a></h3>
                            <ul class="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">The company must be authorised to employ foreign workers.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Issue a formal job offer with clear terms and conditions.</div>
                                </li>
                                <li>
                                    <div class="ttm-list-li-content">Provide a copy of the job contract to the applicant.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Obtain a work permit from the Bulgarian Employment Agency.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Apply for a work visa on behalf of the employee.</div>
                                </li><li class="pb-5">
                                    <div class="ttm-list-li-content">Notify the National Revenue Agency and the National Social Security Institute about the employment.</div>
                                </li><li class="pb-5">
                                    <div class="ttm-list-li-content">Assist the employee with the residence permit application after their arrival.</div>
                                </li><li class="pb-5">
                                    <div class="ttm-list-li-content">Comply with labour and immigration laws throughout the employee's stay.</div>
                                </li>
                            </ul>                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12" id="container-id">
            <div class="featured-imagebox box-shadow" id="container">
                    <div class="featured-thumbnail">
                        <img class="img-fluid_cont" src="images/europe.jpg" alt="" />
                    </div>
                     <div class="featured-content p-30">
                        <div class="featured-title">
                            <h3><a href="">Croatia</a></h3>
                            </div>
                             </div>
                    <div class="overlay" id="hover_right">
                    <div class="text p-30">
                        <div class="featured-desc">
                            <h5>Croatia Employment – Visa Process & Requirements</h5>
                            <div class="featured-title">
                            <h3><a href="">For Companies:</a></h3>
                            </div>
                            <ul class="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Provide the candidate with a comprehensive employment contract.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Compile the necessary documents and submit them to the Croatian authorities to initiate the visa process.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Await approval from Croatian authorities. The candidate can now apply for the visa.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Once in Croatia, the candidate secures a residence permit for work purposes.</div></li>
                                    
                            </ul><br></br>
                            <div class="featured-title">
                            <h3><a href="">For Applicants :</a></h3>
                            </div>
                            <ul class="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Secure a job offer from a Croatian employer.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Gather essential documents, including a valid passport, an employment contract, and health insurance.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Submit the visa application at the Croatian embassy or consulate in your home country.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Await approval from the Croatian authorities.</div></li>
                                    <li class="pb-5">
                                    <div class="ttm-list-li-content">Enter Croatia and finalise work and residence permit formalities.</div></li>
                            </ul>
                        </div> 
                    </div>
                </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12" id="containers-id">
            <div class="featured-imagebox box-shadow" id="container" >
                    <div class="featured-thumbnail">
                        <img class="img-fluid_cont" src="images/prague-by-night.jpg" alt=""/>
                    </div>
                     <div class="featured-content p-30">
                        <div class="featured-title">
                            <h3><a href="">Romania</a></h3>
                            </div>
                             </div>
                    <div class="overlay">
                    <div class="text p-30">
                         <div class="featured-desc">
                            <h5>Romania Employment – Visa Process & Requirements</h5>
                            <div class="featured-title">
                            <h3><a href="">For Applicants</a></h3>
                            </div>
                            <ul class="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Obtain a job offer from a Romanian employer.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Gather essential documents, including a valid passport, an employment contract, health insurance, and educational certificates</div>
                                </li>
                                <li>
                                    <div class="ttm-list-li-content">Submit the visa application along with the required documents to the Romanian embassy or consulate in your home country.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Await approval of the visa application, which may involve processing time.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Once approved, travel to Romania using the issued visa.</div>
                                </li><li class="pb-5">
                                    <div class="ttm-list-li-content">Within a specified time after arrival, apply for a residence permit that allows you to work legally in Romania.</div>
                                </li>
                            </ul><br></br>
                            <div class="featured-title">
                            <h3><a href="">For Companies:</a></h3>
                            <ul class="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Companies must be authorised to hire foreign workers in Romania.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Provide the selected candidate with a formal job offer specifying terms and conditions.</div>
                                </li>
                                <li>
                                    <div class="ttm-list-li-content">Submit necessary documents to the Romanian authorities, including the job offer, candidate details, and company authorization.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">Await processing and approval from the authorities.</div>
                                </li>
                                <li class="pb-5">
                                    <div class="ttm-list-li-content">After company approval, the candidate can apply for the work visa at the Romanian embassy or consulate in their home country.</div>
                                </li><li class="pb-5">
                                    <div class="ttm-list-li-content">Upon arrival in Romania, the candidate can obtain a residence permit for work purposes.</div>
                                </li>
                            </ul>                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</section>

            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Incredible Recruitment & Staffing Agency</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>"We are a seasoned manpower solutions provider with a decade of experience, specialising in offering comprehensive HR services for the Gulf and European regions."</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                href={process.env.PUBLIC_URL + '/current_openings'}>Apply Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}
            <Footer/>
                        
            </div>
          )
      }
   }

export default Candidate_list;