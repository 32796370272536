import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


export class Services_details extends Component {
    render() {
        return (

            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="Current Openings"
                    breadcrumb="Current Openings"
                />
                {/* PageHeader end */}  


          {/* features-section */}
          <section className="ttm-row features-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section title */}
                            <div className="section-title title-style-center_text">
                                <div className="title-header">
                                    <h3>Get <span className="text-theme-SkinColor">Appropriate</span></h3>
                                    <h2 className="title">Search Job By Industry</h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                    </div>{/* row end */}
                    {/* row */}
                    <div className="row row-equal-height mb_10">
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* finance-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: '' }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* finance-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/engineer.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Civil</h3>
                                    </div>
                                    <div className="featured-desc">
<p>Daksh Human Resources provides diverse manpower solutions for the civil sector, including recruitment and placement of skilled laborers, engineers, project managers, site supervisors, surveyors, architects, and structural engineers. Their focus on connecting individuals with opportunities fosters growth and success in the industry.</p>
                                    </div>
                                   <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/civil'}>Apply Jobs!</a> 
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1 active">
                                <div className="ttm-box-view-overlay">
                                    {/* agriculture-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: '' }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* agriculture-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/technician.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Mechanical</h3>
                                    </div>
                                    <div className="featured-desc">
<p>
Daksh Manpower provides a range of jobs in the mechanical industry, including but not limited to positions such as mechanical engineers, design engineers, maintenance technicians, quality control inspectors, production managers, project managers, CNC (computer numeric controlled) operators, and more. Their services cater to various roles within the mechanical sector, ensuring skilled manpower solutions to meet industry demands.    </p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/mechanical'}>Apply Jobs!</a>
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* technology-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage:''  }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* technology-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/oil.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Oil & Gas</h3>
                                    </div>
                                    <div className="featured-desc">
<p>Our partner offers top-notch manpower solutions for the oil and gas sector, offering diverse job placements for technicians, engineers, project managers, and safety specialists. Our curated talent pool is ready for short-term or permanent assignments, contributing to growth and success in the industry.</p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/oil_gas'}>Apply Jobs!</a> 
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* construction-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: '' }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* construction-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/healthcare.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Healthcare</h3>
                                    </div>
                                    <div className="featured-desc">
<p>Our specialized manpower solutions address the healthcare sector's human resource shortfall by providing employment placements for various healthcare fields. We find, screen, and deliver the best talent pool, ensuring high-quality patient care and professional development. Our goal is to transform healthcare organizations and promote the best patient experience.</p>
                                    </div>
                                    <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/healthcare'}>Apply Jobs!</a> 
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* manufacturing-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: '' }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* manufacturing-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/hospitality.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Hospitality</h3>
                                    </div>
                                    <div className="featured-desc">
<p>Our company specializes in providing tailored manpower solutions for the hospitality sector, ensuring a skilled and dedicated workforce for various roles. We focus on sourcing, recruiting, and placing personnel who embody excellence and customer service.</p>
                                    </div>
                                     <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/hospitality'}>Apply Jobs!</a> 
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>                        
                        <div class="col-lg-6 col-md-6 col-sm-6">
    <div class="featured-icon-box icon-align-top-content style1 active">
        <div class="ttm-box-view-overlay">
            <div class="ttm-col-bgimage-yes ttm-bg h-100">
                <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div class="layer-content"></div>
            </div>
        </div>
        <div class="featured-icon">
            <div class="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg"><img class="img-fluid" src="images/facility-management.png" /></div>
        </div>
        <div class="featured-content">
            <div class="featured-title"><h3>Facility Management</h3></div>
            <div class="featured-desc">
                <p>
                    Our facility and management services offer tailored personnel solutions for various positions, including technicians, maintenance, and administrative professionals. We specialize in finding, hiring, and placing suitable
                    individuals for various industries, ensuring efficient operations, managing maintenance, and supervising logistics. Our commitment to quality and understanding business demands ensures successful facilities.
                </p>
            </div>
            <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/facility_management'}>Apply Jobs!</a>         </div>
    </div>
</div>
<div class="col-lg-6 col-md-6 col-sm-6">
    <div class="featured-icon-box icon-align-top-content style1">
        <div class="ttm-box-view-overlay">
            <div class="ttm-col-bgimage-yes ttm-bg h-100">
                <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                <div class="layer-content"></div>
            </div>
        </div>
        <div class="featured-icon">
            <div class="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg"><img class="img-fluid" src="images/information-technology.png" /></div>
        </div>
        <div class="featured-content">
            <div class="featured-title"><h3>Information Technology</h3></div>
            <div class="featured-desc">
                <p>
                    Experts in providing customized personnel solutions for IT industry, offering various positions including project management, systems analysis, network engineering, software development, and cyber security. Comprehensive
                    candidate selection ensures technical capabilities and adaptability, fostering IT excellence and success.
                </p>
            </div> <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/information_technology'}>Apply Jobs!</a> 
        </div>
    </div>
</div>

                        <div className="col-lg-6 col-md-6 col-sm-6">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box icon-align-top-content style1">
                                <div className="ttm-box-view-overlay">
                                    {/* transport-hover */}
                                    <div className="ttm-col-bgimage-yes ttm-bg h-100">
                                        <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: '' }}></div>
                                        <div className="layer-content"></div>
                                    </div>{/* transport-hover end */}
                                </div>
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-lg">
                                        <img className="img-fluid" src="images/shopping-store.png" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Retail</h3>
                                    </div>
                                    <div className="featured-desc">
<p>Daksh Human Resources is a leading provider of tailored manpower solutions and services in the retail sector, offering diverse job opportunities for various roles. Their comprehensive database ensures candidates possess the necessary skills and passion for success. They connect businesses with the right talent, meeting customer expectations and driving growth in a competitive market.</p>
                                    </div>
                                   <a className="ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-dark" 
                                    href={process.env.PUBLIC_URL + '/retail'}>Apply Jobs!</a> 
                                </div>
                            </div>{/* featured-icon-box end */}
                        </div>
                       
                    </div>{/* row end */}
                </div>
            </section>
            {/* features-section end */}
            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Incredible Recruitment & Staffing Agency</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>"We are a seasoned manpower solutions provider with a decade of experience, specialising in offering comprehensive HR services for the Gulf and European regions."</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                href={process.env.PUBLIC_URL + '/current_openings'}>Apply Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}
            <Footer/>
                        
            </div>
          )
      }
   }

export default Services_details;