import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            
            <footer className="footer widget-footer bg-theme-DarkColor text-theme-WhiteColor clearfix">
                <div className="second-footer">
                    <div className="container">                       
                        <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-5 col-lg-5 widget-area">
                                <div className="widget widget clearfix">
                                    <h3 className="widget-title">Quick Contact!</h3>
                                    <ul className="widget_contact_wrapper">
                                    <li>
                                            <i className="ttm-textcolor-skincolor flaticon-placeholder"></i>
                                            <p>Address : F-24, Second Floor, Bhagat Singh Market,<br></br> Gole Market, New Delhi, Delhi 110001 India.</p></li>
                                        
                                        <li>
                                            <i className="ttm-textcolor-skincolor flaticon-phone-call"></i>
                                            <p>Call Us On : <a href="rel:011-43561600"> 011-43561600 / 45623694</a></p>
                                            </li>
                                            <li>
                                            <i className="ttm-textcolor-skincolor flaticon-email"></i>
                                            <p>Email : <a href="mailto:hr@dakshnetworking.com">hr@dakshnetworking.com</a> </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 widget-area">
                                <div className="widget widget-recent-post clearfix">
                                    <h3 className="widget-title" id="tittle">Quick Links</h3>
                                    <ul className="widget-post ttm-recent-post-list"> 
                                    <li><a href={process.env.PUBLIC_URL + '/'}>Home</a> </li>
                                    <li><a href={process.env.PUBLIC_URL + '/about_us'}>About Us</a> </li>
                                    <li><a href={process.env.PUBLIC_URL + '/services'}>Our Services</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/countries_we_serve'}>Countries we serve</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/faq'}>FAQs</a></li>                    
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 widget-area">
                                <div className="widget widget_text clearfix">
                                <h3 className="widget-title" id="tittle">Social Media</h3>
                                  <div className="d-flex align-items-center">
                                        <div className="social-icons">
                                            <ul className="social-icons list-inline">
                                                <li><a href="https://www.facebook.com/dakshhumanresource" target="_blank" rel="noopener" aria-label="facebook">
                                                    <i className="ti ti-facebook"></i></a>
                                                </li>
                                                <li><a href="https://www.linkedin.com/company/daksh-networking-pvt.-ltd/about/" rel="noopener" aria-label="linkedin"><i class="ti ti-linkedin"></i></a></li>
                                                <li><a href="https://www.instagram.com/dakshhumanresource/" target="_blank" rel="noopener" aria-label="instagram">
                                                    <i className="ti ti-instagram"></i></a>
                                                </li>
                                                <li><a href="https://www.youtube.com" target="_blank" rel="noopener" aria-label="youtube">
                                                    <i className="ti ti-youtube"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <img id="footer-logo-img" className="img-fluid auto_size" src="images/mea.png" alt="footer-logo" /> */}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer-text">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <span className="copyright">Copyright © 2023 <a href={process.env.PUBLIC_URL + '/'}> Daksh Networking Pvt. Ltd.</a> | <a href="https://apacedigitalcargo.com/" target="_blank">Apace Digital Cargo</a> | All Rights Reserved.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                
            </footer>
        )
    }
}


