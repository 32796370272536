import React, { Component } from 'react';
import Slider from 'react-slick';
import ProgressBar from 'react-animated-progress-bar';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import Video from '../components/layout/Video';


export class About_us extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    render() {
      var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [ {

            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },{
    
          breakpoint: 778,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 575,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }]
      };
        
        return (       
          <div className="site-main">

            <Header/>
            
            {/* PageHeader */} 
            <PageHeader
                title="About Us "
                breadcrumb="About Us "
            />
            {/* PageHeader end */} 

            
            {/* about-section */}
            <section className="ttm-row about-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-6">
                            {/* section title */}
                            <div className="section-title">
                                <div className="title-header"> 
                                    <h2 className="title">About Us</h2>
                                </div>
                                <div className="title-desc">
<p>Founded in 2013, Daksh Human Resources has emerged as a pioneering force in the realm of overseas recruitment and manpower solutions in India. With a visionary approach and a commitment to excellence, Daksh Human Resources has steadily grown to become a trusted partner for businesses across the Middle East, Europe, and Eastern Europe. Our journey began with the recognition of a pressing need in the global market for a reliable and efficient recruitment agency that could bridge the gap between talent demand and supply.</p>
<p>Daksh Human Resources is not just another recruitment agency; we are an entity duly registered and approved by the Ministry of External Affairs (MEA) of India. Our official registration under the MEA bears testament to our credibility and legitimacy. Our Registration number, B-0497/DEL/COM/1000+/5/8948/2013, stands as a symbol of our compliance and dedication to ethical recruitment practices.</p>                                </div>
<p>Daksh HR specialises in providing a comprehensive array of Indian workers, ranging from skilled and semi-skilled to unskilled labour. Our client base spans across various industries, including construction, oil and gas, transportation, and more. We take pride in meticulously selecting and preparing Indian talent to meet the unique requirements of each project and industry.</p>                            
<p>Our reach extends beyond borders. With a focus on the Middle East, Europe, and Eastern Europe, we have cultivated an extensive network that allows us to identify, assess, and present the most suitable candidates for our clients' needs. Our expertise in navigating international regulations and requirements ensures a seamless recruitment process for both clients and candidates.</p>                        
                            
                            </div>{/* section title end */}<br></br>
                        </div>
                        <div className="col-lg-6">
                            <div className="mr-30 mt-30 mt-lg-60 mb-lg-30">
                                <div className="d-flex">
                                    <img src="images/au.jpg" className="img-fluid" alt="" />
                                    <div className="flex-basis-auto ml_180 mr_30 mt_30 z-index_1">
                                        <img src="images/dot-pattern.png" className="img-fluid" alt="dot-pattern" />
                                    </div>
                                    <div className="d-flex justify-content-end flex-column ml_180 mr_30 mb_35 z-index_1">
                                        <img src="images/dot-pattern.png" className="img-fluid" alt="dot-pattern" />
                                    </div>
                                </div>
                            </div><br></br>
                            <div className="ttm-tabs ttm-tab-style-01">
                                <Tabs>
                                    <TabList className="tabs">
                                        <Tab className="tab"><a className="tab-1" tabIndex="0">Our Mission</a></Tab>
                                        <Tab className="tab"><a className="tab-2" tabIndex="0">Our Vision</a></Tab>
                                    </TabList> 
                                    <div className="content-tab">                                
                                        <TabPanel>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3 col-sm-4">
                                                <img className="img-fluid alignleft" height="200" width="200" src="images/tab-img.jpg" alt="image" />
                                            </div>
                                            <div className="col-lg-8 col-md-9 col-sm-8">
                                                <div className="pt-15">
                                                    <p>Our mission is clear and resolute: to connect talent with opportunities, fostering a symbiotic relationship between individuals and businesses. We are dedicated to providing exceptional manpower solutions and services that cater specifically to the Gulf and European regions. With a commitment to integrity, quality, and innovation, we strive to be the bridge that closes the gap between skilled candidates and organizations seeking their expertise. Our mission is not just about recruitment; it's about empowerment, growth, and the pursuit of excellence.</p>
                                                </div>
                                            </div>
                                        </div>
                                        </TabPanel>
                                        <TabPanel>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3 col-sm-4">
                                                <img className="img-fluid alignleft" height="200" width="200" src="images/single-img.jpg" alt="image" />
                                            </div>
                                            <div className="col-lg-8 col-md-9 col-sm-8">
                                                <div className="pt-15">
                                                    <p>Our vision is to redefine the standards of excellence in the realm of manpower solutions for the Gulf and Europe. We aspire to be the preferred partner for businesses seeking exceptional talent and individuals pursuing meaningful career opportunities. </p>
                                               <p>Through our unwavering commitment to integrity, innovation, and personalised service, we aim to bridge the gap between talent and opportunity, fostering growth, success, and prosperity for both our clients and the talented individuals we connect them with. Our vision is to continue shaping the recruitment landscape, setting new benchmarks in quality, reliability, and ethical practices while contributing to the advancement of industries and economies in the regions we serve.</p>
                                                </div>
                                            </div>
                                        </div>
                                        </TabPanel>
                                        <TabPanel>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3 col-sm-4">
                                                <img className="img-fluid alignleft" height="200" width="200" src="images/single-img.jpg" alt="image" />
                                            </div>
                                            <div className="col-lg-8 col-md-9 col-sm-8">
                                                <div className="pt-15">
                                                    <p>Our vision is to redefine the standards of excellence in the realm of manpower solutions for the Gulf and Europe. We aspire to be the preferred partner for businesses seeking exceptional talent and individuals pursuing meaningful career opportunities. </p>
                                                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">An ability to attract and retain great talent</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">A talent need, quickly and cost-effectively</div></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        </TabPanel>
                                    </div>       
                                </Tabs>
                            </div> 
                        </div>
                    </div>{/* row end */}
                </div>
            </section>
            {/* about-section end */}

            <section className="ttm-row about1-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="ttm-tabs ttm-tab-style-01">
                            <div className="title-header"> 
                                    <h2 className="title">Why choose us? </h2>
                                </div>
                                <div className="title-desc">
                                    <p>Daksh HR's success lies in its commitment to integrity, quality, and reliability. Our team of experts is dedicated to understanding the intricacies of each industry and tailoring our solutions accordingly. We consider ourselves not just recruiters but facilitators of growth, matching talent with opportunities to contribute to the success of businesses and the enrichment of individuals.</p>
                                    <p>Join us in our journey to empower businesses with the right talent and uplift the careers of countless individuals across the globe. At Daksh Human Resources, the pursuit of success through manpower solutions is our driving force.</p>
</div>
                            </div>
                            </div>
                            </div>
                            </div>
                            </section>
                     
            {/* padding_zero-section */}
            <section className="ttm-row padding_bottom_zero-section bg-layer-equal-height mt_30 mt_lg-60 clearfix">
                <div className="container p-0">
                    <div className="row g-0">
                        <div className="col-lg-8">
                            <div className="ttm-bg ttm-bg ttm-col-bgimage-yes col-bg-img-five ttm-left-span spacing-3">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: 'url(images/bg-image/col-bgimage-5.jpg)' }}>
                                    <div className="ttm-col-wrapper-bg-layer-inner"></div>
                                </div>
                                <div className="layer-content">
                                    {/* section title  */}
                                    <div className="section-title">
                                        <div className="title-header">
                                            <h2 className="title">Director‘s Message</h2>
                                        </div>
                                        <div className="title-desc">
                                            <h5>“If one takes care of the means, the end will take care of itself"</h5>
                                            <p>In its pursuit of excellence, the above quote from Mahatma Gandhi has been a source of inspiration to the founders of Daksh Networking Pvt. Ltd. and its employees. </p>
                                        </div>
                                        <div className="title-desc">
<p>Since 2013 Daksh Networking has established some good relationships with overseas companies. During this tenure we have placed more than 60,000 candidates in the Gulf and Europe. We are leaders in Manpower, recruitment and placement in India to Gulf and Europe.</p>
<p>Recruitment is a complex and also, too often,it takes a long time to select the right person needed so we are at your disposal. Therefore, we have a young, dynamic & motivated team to cater all the requirements of Employers as well as Interview, screening and documentation of Candidates.</p>
<p>The journey of Daksh Human Resource is never ending. But with the support of my team and clients, I am confident that the company will continue to scale milestones of excellence for years to come.
</p></div><br></br>
<div className="title-desc">
    <p>Best Regards!</p>
</div><br></br><br></br>

                                    </div>{/* section title end  */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12">
                            {/* col-img-img-six */}
                            <div className="ttm-bg ttm-col-bgimage-yes col-bg-img-six ttm-right-span z-index-2 ml_120 ml_lg-0">

                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{ backgroundImage: 'url(images/director.jpg)' }}></div>                                <div className="layer-content">
                                </div>
                                <img className="img-fluid col-bg-img-res" src="images/director.jpg" alt="bgimage" />
                            </div>{/* col-img-bg-img-six end */}
                        </div>
                    </div>{/* row end */}
                </div>
            </section>
            {/* padding_zero-section end */}

            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Incredible Recruitment & Staffing Agency</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>"We are a seasoned manpower solutions provider with a decade of experience, specialising in offering comprehensive HR services for the Gulf and European regions."</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                href={process.env.PUBLIC_URL + '/current_openings'}>Apply Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}


            <Footer/>
                        
         </div>
       )
   }
}


export default About_us;