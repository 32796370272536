import React, { Component } from 'react';
import Slider from 'react-slick';
import ProgressBar from 'react-animated-progress-bar';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';


export class Services extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    render() {
      var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [ {

            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },{
    
          breakpoint: 778,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 575,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }]
      };
        
        return (       
          <div className="site-main">

            <Header/>
            
            {/* PageHeader */} 
            <PageHeader
                title="FAQs"
                breadcrumb="FAQs"
            />
            {/* PageHeader end */} 

            <section class="ttm-row job-list-section ttm-bg clearfix">
            <div class="container">
            <div class="row">
            <div class="col-lg-12">
                <div class="section-title title-style-center_text">
                    <div class="title-header">
                        <h2 class="title">FAQs</h2>
                    </div>
                </div>
            </div>
        </div>
                            <div className="row">
        <div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">What are Manpower Solutions and Services?</button>
  <div class="dropdown-content">
    <a href="#">Manpower Solutions and Services refer to comprehensive workforce management solutions that help businesses optimise their staffing, recruitment, training, and human resource needs.</a>
  </div>
 </div>
</div>
<div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">What services do manpower solution providers offer?</button>
  <div class="dropdown-content">
    <a href="#">Manpower solution providers offer services such as recruitment, staffing, training, workforce planning, performance management, and HR consulting.</a>
  </div>
 </div>
</div>
<div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">How do they source and recruit candidates?</button>
  <div class="dropdown-content">
    <a href="#">Manpower solution providers source candidates through various channels like job portals, social media, networking, and databases. They also use their expertise to screen, interview, and match candidates to job roles.</a>
  </div>
 </div>
</div>
<div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">How does workforce planning play a role in manpower solutions?

</button>
  <div class="dropdown-content">
    <a href="#">Workforce planning involves analysing current and future staffing needs to ensure the right talent is available at the right time, preventing overstaffing or understaffing.</a>
  </div>
 </div>
</div>
<div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">Can Manpower Solutions help with international recruitment?</button>
  <div class="dropdown-content">
    <a href="#">Yes, some providers offer global recruitment services, helping businesses find talent from various countries to meet their diverse needs.</a>
  </div>
 </div>
</div>
<div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">How do I choose the right manpower solutions provider?
</button>
  <div class="dropdown-content">
    <a href="#">Consider factors like industry expertise, reputation, client testimonials, and range of services, technology utilisation, and ability to scale with your business.</a>
  </div>
 </div>
</div>
<div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">Are manpower solutions cost-effective?</button>
  <div class="dropdown-content">
    <a href="#">Yes, outsourcing manpower needs can be cost-effective as it reduces recruitment costs, HR overheads, and administrative burdens.</a>
  </div>
 </div>
</div>
<div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">
What is the difference between staffing and recruitment?</button>
  <div class="dropdown-content">
    <a href="#">Recruitment focuses on finding and hiring suitable candidates for open positions, while staffing involves placing candidates in temporary or contract roles within an organisation.</a>
  </div>
 </div>
</div>
<div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">
Does Manpower Solutions offer training services?</button>
  <div class="dropdown-content">
    <a href="#">Yes, many companies offer training programmes to enhance the skills of both temporary and permanent staff.</a>
  </div>
 </div>
</div>
<div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">
  Who can benefit from manpower solutions and services?</button>
  <div class="dropdown-content">
    <a href="#">Manpower solutions are valuable to a wide range of industries, including manufacturing, healthcare, retail, hospitality, IT, construction, and more. Any business facing fluctuating labour needs or seeking specialised skills can benefit from these services.
</a>
  </div>
 </div>
</div>
<div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">
 Why it is important to work with a renowned recruitment Agency?</button>
  <div class="dropdown-content">
    <a href="#">Working with a top recruitment agency is guaranteed to save you time, money and the stress of risking a poor hire. The most substantial benefit of working with a recruitment agency is the improved quality of hire which boils down to the agency's coveted talent pool. It takes several years for agencies to build out networks of great candidates. This is where they compete and differentiate amongst themselves and it's a critical indicator of the agency's quality. If you are recruiting for hard-to-fill roles or rare tech skills in this fiercely competitive space— which is likely why you're considering hiring an agency — you'll want to make sure your agency has a deep running network of stealth talent, the kind you'd never find on your own.
</a>
  </div>
 </div>
</div>
<div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">
  Does daksh provide manpower solutions in white collar jobs as well?</button>
  <div class="dropdown-content">
    <a href="#">Yes, Daksh Manpower offers comprehensive manpower solutions for white-collar jobs across diverse industries, including IT, healthcare, banking, and more. Their expertise spans various sectors, providing tailored staffing solutions to meet the specific needs of each industry.
</a>
  </div>
 </div>
</div>
<div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">
  What is Your Cv Selection Process?</button>
  <div class="dropdown-content">
    <a href="#">The idea of hiring a recruitment agency is to save time, money, and mitigate the risks of a bad hire. But you want to make sure the CVs that make it to your desk is well suited for the role. If they aren't, make sure to communicate what requirements are getting lost in translation, so the recruiters correct it in their selection. Also, ask about the pre-screening process and how many CVs you can expect them to provide.
</a>
  </div>
 </div>
</div>
<div className="col-lg-6" id="tab">
      <div class="dropdown">
  <button class="dropbtn">
  What Are Your Placement Guarantees?</button>
  <div class="dropdown-content">
    <a href="#">
    All reputable recruitment agencies offer a one to the three-month grace period for their placements. Providing this assurance is part of the reason recruitment agency fees are relatively high compared to those of freelance recruiters. A grace period is a time during which the agency will replace the candidate at no charge if things don't work out. </a>
  </div>
 </div>
</div>
</div>
                          </div>

                          </section>

            {/* services-section */}

            {/* services-section end */}

            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Incredible Recruitment & Staffing Agency</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>"We are a seasoned manpower solutions provider with a decade of experience, specialising in offering comprehensive HR services for the Gulf and European regions."</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                href={process.env.PUBLIC_URL + '/current_openings'}>Apply Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}


            <Footer/>
                        
         </div>
       )
   }
}

export default Services;




