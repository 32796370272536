import React, { Component } from 'react'
import Menu from './Menu';
import Mobile_menu from './Mobile_menu';
import Logo from './Logo'
import Header_search from './Header_search'


export class Header extends Component {
    
    componentDidMount() {
        window.addEventListener('scroll', this.isSticky);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.isSticky);
    }

    isSticky = (e)=>{
        const header = document.querySelector('header');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('is-Sticky') : header.classList.remove('is-Sticky');
    };

    render() {        
        return (
                
            <header id="masthead" className="header ttm-header-style-01">
                {/* topbar */}
               <div className="top_bar bg-theme-DarkColor text-center clearfix">
                    <div className="container">
                        <div className="row">
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
                            <div class="widget widget clearfix">
                                    <ul class="widget_contact_wrapper"><li>
                                        <i class="ttm-textcolor-skincolor flaticon-phone-call"></i>
                                        <p>Call Us On : <a href="rel:011-43561600"> 011-43561600 / 45623694

</a>
                                            </p>
                                            </li>
</ul></div></div>
<div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 widget-area">
    <div class="widget widget clearfix">
        <ul class="widget_contact_wrapper">
            <li><i class="ttm-textcolor-skincolor flaticon-email"></i>
            <p>Email : <a href="mailto:hr@dakshnetworking.com"> hr@dakshnetworking.com</a> </p>
            </li>
            </ul>
            </div>
            </div>                     
            <div class="col-xs-12 col-sm-6 col-md-6 col-lg-2 widget-area">
                <div class="widget widget_text clearfix">
                   <div class="d-flex align-items-center">
                    <div class="social-icons">
                    <div className="header_social">
                                           
         <ul className="social-icons"><p>    Social Media : 
                                                            <li><a href="https://www.facebook.com/dakshhumanresource" rel="noopener" aria-label="facebook"><i className="ti ti-facebook"></i></a></li>
                                                            <li><a href="https://www.instagram.com/dakshhumanresource/" rel="noopener" aria-label="instagram"><i className="ti ti-instagram"></i></a></li>
                                                            <li><a href="https://www.linkedin.com/company/daksh-networking-pvt.-ltd/about/" rel="noopener" aria-label="linkedin"><i className="ti ti-linkedin"></i></a></li>
                                                            <li><a href="https://www.youtube.com/" rel="noopener" aria-label="youtube"><i className="ti ti-youtube"></i></a></li>

                                                            </p>   </ul>
                                                    </div>
                        </div></div>
                        </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-2 widget-area">
                <div class="widget widget_text clearfix">
                   <div class="d-flex align-items-center">
                    <div class="social-icons">
                    <div className="header_logo">
                    <img id="footer-logo-img" className="img-fluid auto_size" src="images/meaa.png" alt="footer-logo" />     
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       </div>
                {/* topbar end */}
                {/* site-header-menu */}
                <div id="site-header-menu" className="site-header-menu ttm-bgcolor-white">
                    <div className="site-header-menu-inner ttm-stickable-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* site-navigation */}
                                    <div className="site-navigation d-flex align-items-center justify-content-between">
                                        {/* site-branding */}
                                        <div className="site-branding">
                                            <Logo/>
                                        </div>
                                        {/* site-branding end */}
                                        <div className="border-box-block">
                                            <div className=" d-flex align-items-center justify-content-between">
                                                {/* menu */}
                                                <Menu/>
                                                <div className="mobile-menu"><Mobile_menu/></div>
                                                {/* menu end */}
                                                <div className="header_extra ml-auto d-flex align-items-center">
                                                    <Header_search/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header_btn">
                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                            href={process.env.PUBLIC_URL + '/current_openings'}>Apply Now!</a>
                                        </div>
                                    </div>{/* site-navigation end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* site-header-menu end */}
            </header> 
            
        )
    }
}

export default Header;