import React, { Component } from 'react';
import {BrowserRouter as Router} from 'react-router-dom';

export class Menu extends Component {
    state = {
            show: false,
    }
    render() {
        return (
            <Router>
                <nav className="main-menu">         
                    <ul className="menu">
                        <li><a href={process.env.PUBLIC_URL + '/'}>Home</a> </li>
                        <li><a href={process.env.PUBLIC_URL + '/about_us'}>About Us</a> </li>
                        <li><a href={process.env.PUBLIC_URL + '/services'}>Our Services</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/our_process'}>Our Process</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/countries_we_serve'}>Countries we serve</a></li>                    
                        <li><a href={process.env.PUBLIC_URL + '/current_openings'}>Current Openings</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/faq'}>FAQs</a></li>
                        <li><a href={process.env.PUBLIC_URL + '/contact_us'}>Contact Us</a></li>
                    </ul>
                </nav>
            </Router>
        )
        
    }
}

export default Menu;