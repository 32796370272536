import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


export class Services_details extends Component {
    render() {
        return (

            <div className="site-main">
                <Header/>
            
                {/* PageHeader */} 
                <PageHeader
                    title="Mechanical"
                    breadcrumb="Mechanical"
                />
                {/* PageHeader end */}  


                <section class="ttm-row about-section clearfix">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
            <div className="ttm-tabs ttm-tab-style-01">
                                <Tabs>
                                    <TabList className="tabs">
                                        <Tab className="tab"><a className="tab-1" tabIndex="0">Window AC Technician</a></Tab>
                                        <Tab className="tab"><a className="tab-2" tabIndex="0">HVAC Technician</a></Tab>
                                        <Tab className="tab"><a className="tab-3" tabIndex="0">Skid Operator</a></Tab>

                                    </TabList> 
                                    <div className="content-tab">                                
                                        <TabPanel>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3 col-sm-4">
                                                <img className="img-fluid alignleft" height="100%" width="100%" src="images/ac.jpg" alt="image" />
                                            </div>
                                            <div className="col-lg-8 col-md-9 col-sm-8">
                                                <div className="pt-15">
                                                    <h3>Window AC Technician</h3>
                                                    <p><b>Minimum Experience:</b> 04-06 Years Experience In same Field</p>
                                                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                        <h5>Terms & Conditions:</h5>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">08 Hours Duty</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">overtime will be as per the labour law of the particular country.</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Free Accommodation & Transportation by Company</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Medical Insurance by company</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">2 years renewable contract</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Visa ready & Immediate Flight Departure</div></li>
                                                    </ul><br></br>
                                                    <h6>For more information related to this opening, please contact DAKSH NETWORKING at<br></br><a href="mailto:hr@dakshnetworking.com">hr@dakshnetworking.com</a></h6>
                                                </div>
                                            </div>
                                        </div>
                                        </TabPanel>
                                        <TabPanel>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3 col-sm-4">
                                                <img className="img-fluid alignleft" height="100%" width="100%" src="images/hv.jpg" alt="image" />
                                            </div>
                                            <div className="col-lg-8 col-md-9 col-sm-8">
                                                <div className="pt-15">
                                                    <h3>HVAC Technician</h3>
                                                    <p><b>Minimum Experience:</b> Gulf Return with 3 years experience</p>
                                                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                        <h5>Terms & Conditions:</h5>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">8 Hours Duty / 6 Days working</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Free Accommodation & Transportationby Company</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Medical Insurance by company</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">2 years renewable contract</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Visa ready & Immediate Flight Departure</div></li>
                                                    </ul><br></br>
                                                    <h6>For more information related to this opening, please contact DAKSH NETWORKING at<br></br><a href="mailto:hr@dakshnetworking.com">hr@dakshnetworking.com</a></h6>
                                                </div>
                                            </div>
                                        </div>
                                        </TabPanel>
                                        <TabPanel>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-3 col-sm-4">
                                                <img className="img-fluid alignleft" height="100%" width="100%" src="images/so.jpg" alt="image" />
                                            </div>
                                            <div className="col-lg-8 col-md-9 col-sm-8">
                                                <div className="pt-15">
                                                    <h3>Skid Operator</h3>
                                                    <p><b>Minimum Experience:</b> Gulf Return with 3 years experience</p>
                                                    <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                        <h5>Terms & Conditions:</h5>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">8 Hours Duty / 6 Days working</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Free Accommodation & Transportation by Company</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Medical Insurance by company</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">2 years renewable contract</div></li>
                                                        <li><i className="far fa-check-circle"></i><div className="ttm-list-li-content">Visa ready & Immediate Flight Departure</div></li>
                                                    </ul><br></br>
                                                    <h6>For more information related to this opening, please contact DAKSH NETWORKING at<br></br><a href="mailto:hr@dakshnetworking.com">hr@dakshnetworking.com</a></h6>
                                                </div>
                                            </div>
                                        </div>
                                        </TabPanel>
                                    </div>       
                                </Tabs>
                            </div>
            </div>
        </div>
    </div>
</section>
          
 <section class="form-section clearfix"><div class="container">
<div class="row"><div class="col-lg-12">
<div class="section-title title-style-center_text">
<div class="title-header">
<h3>fill the <span class="text-theme-SkinColor"> details</span>
</h3><h2 class="title">Apply For Your Jobs</h2>
</div>
</div>
</div>
</div>
<div class="row">
<div class="col-lg-12">
<form class="submit_Form wrap-form row row-equal-height">
<div class="col-lg-6"><div class="basic-details p-30 mt-15 box-shadow">
<div class="mb-20 text-center"><h4>Personal Details</h4><p>Please fill out your personal details here.</p></div>
<div class="row">
    <div class="col-lg-6">
        <label><input type="text" name="first_name" placeholder="First Name"></input>
            </label>
            </div>
            <div class="col-lg-6">
                <label><input type="text" name="last_name" placeholder="Last Name"></input></label>
                </div>
                <div class="col-lg-6">
                    <label>
                        <input type="email" name="email_id" placeholder="Email"></input>
                            </label>
                            </div>
                            <div class="col-lg-6">
                                <label><input type="tel" name="phone_number" placeholder="Phone"></input>
                                    </label>
                                    </div>
                                    <div class="col-lg-6">
                <label><input type="text" name="passport_no" placeholder="Passport No"></input></label>
                </div>
                                    <div class="col-lg-6">
                                        <label>
                                            <input type="text" name="address" placeholder="Address"></input></label>
                                            </div>
                                            </div>
                                            </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="additional-details p-30 mt-15 box-shadow">
                                                    <div class="mb-20 text-center">
                                                        <h4>Qualifications</h4>
                                                        <p>Please fill out your qualification details here.</p>
                                                        </div>
                                                        <div class="row">
                                                        <div class="col-lg-6">
                <label><input type="text" name="education" placeholder="Education"></input></label>
                </div>
                                                                <div class="col-lg-6">
                                                                    <label><select name="locations" aria-hidden="true" placeholder="Locations">
                                                                        <option value="">Locations</option>
                                                                        <option value="Loc1">UAE</option>
                                                  <option value="Loc2">Oman</option>
                                                  <option value="Loc3">Saudi Arabia</option>
                                                  <option value="Loc4">Kuwait</option>
                                                  <option value="Loc5">Bulgaria</option>
                                                  <option value="Loc6">Croatia</option>
                                                  <option value="Loc6">Romania</option>
                                              </select>
                                                                        </label>
                                                                        </div>

                                                                        <div class="col-lg-6">
                <label><input type="text" name="experience" placeholder="Experience"></input></label>
                </div>

            <div class="col-lg-6">
                <label>
                    <input type="text" name="note" placeholder="Personal Qualities"></input></label>
                </div>
                <div class="col-lg-12">
                    <label>
                        <input type="file" name="chooseFile" id="chooseFile"></input></label>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div class="col-lg-12">
                            <label><textarea name="message" rows="4" placeholder="Additional Information....." required=""></textarea>
                            </label>
                            </div>
                            <div class="col-lg-12">
                                <label class="mb-0">
                                    <button class="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
 ttm-btn-color-skincolor h-auto" type="submit">Apply For This Jobs</button>
 </label>
 </div>
 </form>
 </div>
 </div>
 </div>
 </section>
  {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Incredible Recruitment & Staffing Agency</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>"We are a seasoned manpower solutions provider with a decade of experience, specialising in offering comprehensive HR services for the Gulf and European regions."</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                href={process.env.PUBLIC_URL + '/current_openings'}>Apply Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}
            <Footer/>
                        
            </div>
          )
      }
   }

export default Services_details;