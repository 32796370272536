import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';

export class Contact_01 extends Component {
    render() {
        
        return (
            <div className="site-main">
                <Header />

                {/* PageHeader */} 
                <PageHeader           
                    title="Contact Us"
                    breadcrumb="Contact Us" 
                />
                {/* PageHeader end */}

                {/* process-section */}
                <section className="ttm-row process-section bg-theme-GreyColor clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="featuredbox-number bg-theme-WhiteColor box-shadow p-30 mt-15">
                                    <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box icon-align-top-content style6"> 
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round">
                                                    <img src="images/address.png"></img>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>Address</h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>F-24, Second Floor, Bhagat Singh Market, Gole Market, New Delhi, Delhi 110001 India.</p>
                                                    </div>
                                                </div>
                                            </div>{/* featured-icon-box end */}
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box icon-align-top-content style6">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round">
                                                      <img src="images/phone-call.png"></img>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>Call Us</h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p><a href="rel:011-43561600">011-43561600 / 45623694</a></p>
                                                    </div>
                                                </div>
                                            </div>{/* featured-icon-box end */}
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box icon-align-top-content style6">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-fill ttm-icon_element-size-lg ttm-icon_element-color-grey ttm-icon_element-style-round">
                                                    <img src="images/email.png"></img>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3>Email</h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p><a href="mailto:hr@dakshnetworking.com">hr@dakshnetworking.com</a></p>
                                                    </div>
                                                </div>
                                            </div>{/* featured-icon-box end */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* row end */}
                    </div>
                </section>
                {/* process-section end */}


                {/* map-section */} 
                <section className="map-section bg-theme-GreyColor clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12">
                                {/* section title */}
                                <div className="section-title title-style-center_text pb-15">
                                    <div className="title-header">
                                        <h3>get in <span className="text-theme-SkinColor">touch</span></h3>
                                        <h2 className="title">Feel Free To Contact</h2>
                                    </div>
                                </div>{/* section title end */} 
                            </div>
                            <div className="col-lg-6">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56031.318196342894!2d77.15790095918652!3d28.631039215672686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd66709034d1%3A0x52bf3ae2ba376ef1!2sGol%20Market%20NEW%20Delhi!5e0!3m2!1sen!2sin!4v1690531068662!5m2!1sen!2sin" width="100%" height="525"> </iframe>
                                                        </div>
                            <div className="col-lg-6">
                                <div className="ttm-col-bgcolor-yes ttm-bg bg-theme-WhiteColor z-index-2 p-40 p-lg-30 mt-lg-30">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                    <div className="layer-content">
                                        <form id="contact_form" className="contact_form wrap-form">
                                            <div className="row ttm-boxes-spacing-10px">
                                                <div className="col-md-12 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="name" type="text" placeholder="Your Name" required="required" />
                                                    </label>
                                                </div>
                                                <div className="col-md-6 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="email" type="text" placeholder="Your Email" required="required" />
                                                    </label>
                                                </div>
                                                <div className="col-md-6 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="subject" type="text" placeholder="Subject" required="required" />
                                                    </label>
                                                </div>
                                                <div className="col-md-12 ttm-box-col-wrapper">
                                                    <label>
                                                        <input name="phone" type="text" placeholder="Phone Number" required="required" />
                                                    </label>
                                                </div>
                                                <div className="col-md-12 ttm-box-col-wrapper">
                                                    <label>
                                                        <textarea name="message" rows="7" placeholder="Message" required="required"></textarea>
                                                    </label>
                                                </div>
                                            </div>
                                            <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                            ttm-btn-color-skincolor w-100" type="submit">send a message!</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </section>
                {/* map-section end */} 

            {/* action-section */}
            <section className="ttm-row action-section bg-theme-SkinColor text-theme-WhiteColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="d-md-flex align-items-center justify-content-between">
                                {/* featured-icon-box */}
                                <div className="featured-icon-box icon-align-before-content style2">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-white ttm-icon_element-size-xl">
                                            <i className="flaticon flaticon-recruitment"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Incredible Recruitment & Staffing Agency</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>"We are a seasoned manpower solutions provider with a decade of experience, specialising in offering comprehensive HR services for the Gulf and European regions."</p>
                                        </div>
                                    </div>
                                </div>{/* featured-icon-box end */}
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white" 
                                href={process.env.PUBLIC_URL + '/current_openings'}>Apply Now!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* action-section end */}

                <Footer/>

            </div>
        )
    }
}


export default Contact_01;