import React, { Component } from "react";
import Slider from 'react-slick';


export class Banner extends Component {
    state = {
        show: false,
      }
      render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [{
  
            breakpoint: 1199,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1
            }
        },
        {
      
            breakpoint: 1024,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1
            }
        },
        {
      
            breakpoint: 680,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
               
        return (
          
            <Slider className="slick_slider banner_slider banner_slider_1" {...slick_slider} slidesToShow={1} autoplay={false} >
              <div className="slide">
                <div className="slide_img">
                <div className="Video-content">
                 <video  width="100%" loop muted controls autoplay="autoplay"><source src="images/banner_bg.mp4" type="video/mp4"></source> </video>
                </div>
                </div>
                <div className="slide__content">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="slide__content--headings ttm-textcolor-white text-center">
                          <h3  data-animation="animate__animated animate__bounce animate__delay-2s">Daksh Manpower</h3>
                          <div className="d-flex align-items-center justify-content-center">
                            <h2  data-animation="animate__fadeInDown">Manpower Solutions & Services for Gulf and Europe
</h2>
                          </div>
                          <div className="mt-30">
                            <div className="row">
                              <div className="col-lg-12">
                                <p  data-animation="animate__fadeInDown" className="mb-30 mb-md-0">
                                    <form id="b_search_Form" className="b_search_Form wrap-form d-block" method="post" action="#" data-mailchimp="true">
                                      <div className="row row-equal-height">
                                        <div className="col-lg-3">
                                          <label>
                                              <i className="ti ti-search"></i>
                                              <input type="text" id="filter" placeholder="Job Title or Keywords"/>
                                          </label>
                                        </div>
                                        <div className="col-lg-3">
                                          <label>
                                              <i className="ti ti-location-pin"></i>
                                              <select name="locations" aria-hidden="true" placeholder="Locations">
                                                  <option value="">Locations</option>
                                                  <option value="Loc1">UAE</option>
                                                  <option value="Loc2">Oman</option>
                                                  <option value="Loc3">Saudi Arabia</option>
                                                  <option value="Loc4">Kuwait</option>
                                                  <option value="Loc5">Bulgaria</option>
                                                  <option value="Loc6">Croatia</option>
                                                  <option value="Loc6">Romania</option>

                                              </select>
                                          </label>
                                        </div>
                                        <div className="col-lg-3">
                                            <label>
                                              <i className="ti ti-list"></i>
                                              <select name="categories" aria-hidden="true" placeholder="Categories">
                                                  <option value="">Categories</option>
                                                  <option value="Ed1">Civil</option>
                                                  <option value="Ed2">Oil & Gas</option>
                                                  <option value="Ed3">Mechanical</option>
                                                  <option value="Ed4">Facility Management</option>
                                                  <option value="Ed5">Healthcare</option>
                                                  <option value="Ed6">Hospitality</option>
                                                  <option value="Ed7">Information Technology</option>
                                                  <option value="Ed8">Retail</option>
                                              </select>
                                            </label>
                                        </div>
                                        <div className="col-lg-3">
                                          <label>
                                              <button className="submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill 
                                              ttm-btn-color-skincolor" type="submit">Find Jobs</button>
                                          </label>
                                        </div>
                                      </div>
                                    </form>
                                </p>
                                <p  data-animation="animate__fadeInDown" className="d-none d-md-block">
                                  <span className="text-theme-WhiteColor">Popular Searches :  </span>Carpenter, Tailor, Steel Fixer, Mason, Cleaner
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>              
            </Slider>
            
        )
    }
}

export default Banner;