import React, { Component } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/vendor/flaticon.css';
import './css/vendor/fontawesome.min.css';
import './css/vendor/themify-icons.css';

import Home from './pages/Home';

import About_us from './pages/About_us';
import Services from './pages/Services';
import contact_us from './pages/contact_us';
import faq from './pages/faq';
import countries_we_serve from './pages/countries_we_serve';
import current_openings from './pages/current_openings';
import our_process from './pages/our_process';
import civil from './pages/civil';
import oil_gas from './pages/oil_gas';
import mechanical from './pages/mechanical';
import healthcare from './pages/healthcare';
import hospitality from './pages/hospitality';
import facility_management from './pages/facility_management';
import retail from './pages/retail';
import information_technology from './pages/information_technology';

import ScrollToTop from './components/layout/Gotop';


function App() {
 
  return (
    <div className="page">
      <Router> 
        
        <Route exact path={`${process.env.PUBLIC_URL + '/'}`} component={ Home } />

        <Route exact path={`${process.env.PUBLIC_URL + '/About_us'}`} component={ About_us } /> 
        <Route exact path={`${process.env.PUBLIC_URL + '/Services'}`} component={ Services } />
        <Route exact path={`${process.env.PUBLIC_URL + '/contact_us'}`} component={ contact_us } />
        <Route exact path={`${process.env.PUBLIC_URL + '/faq'}`} component={ faq } />
        <Route exact path={`${process.env.PUBLIC_URL + '/our_process'}`} component={ our_process } />
        <Route exact path={`${process.env.PUBLIC_URL + '/countries_we_serve'}`} component={ countries_we_serve } />
        <Route exact path={`${process.env.PUBLIC_URL + '/current_openings'}`} component={ current_openings } />
        <Route exact path={`${process.env.PUBLIC_URL + '/civil'}`} component={ civil } />
        <Route exact path={`${process.env.PUBLIC_URL + '/oil_gas'}`} component={ oil_gas } />
        <Route exact path={`${process.env.PUBLIC_URL + '/mechanical'}`} component={ mechanical } />
        <Route exact path={`${process.env.PUBLIC_URL + '/healthcare'}`} component={ healthcare } />
        <Route exact path={`${process.env.PUBLIC_URL + '/hospitality'}`} component={ hospitality } />
        <Route exact path={`${process.env.PUBLIC_URL + '/facility_management'}`} component={ facility_management } />
        <Route exact path={`${process.env.PUBLIC_URL + '/retail'}`} component={ retail } />
        <Route exact path={`${process.env.PUBLIC_URL + '/information_technology'}`} component={ information_technology } />


        <ScrollToTop />
      </Router>
    </div>
  );
}

export default App;


